<template>
  <div>
    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="payments"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onTableFiltered"
          :foot-clone="!!payments.length"
          no-footer-sorting
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: sortDate -->
          <template #cell(sortDate)="row">
            <span class="text-nowrap">
              {{ row.item.displayDate }}
            </span>
          </template>

          <!-- Column: localizator -->
          <template #cell(bookingSortCheckin)="row">
            <b-booking-localizator
              :localizator="row.item.bookingLocalizator"
              :source="row.item.bookingSource"
              :highlighted="row.item.bookingHighlighted"
              :fictitious="row.item.bookingFictitious"
              :cancelled="row.item.bookingCancelled"
            />
            <div class="mt-25 text-nowrap">
              <small
                class="text-nowrap"
                :class="{
                  'text-warning': !isCurrentYear(
                    row.item.bookingDisplayCheckin
                  ),
                }"
              >
                {{
                  `${row.item.bookingDisplayCheckin} - ${row.item.bookingDisplayCheckout}`
                }}
              </small>
            </div>
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="row">
            <b-accommodation
              :uuid="row.item.accommodation.uuid"
              :name="row.item.accommodation.name"
            />
          </template>

          <!-- Column: method -->
          <template #cell(method)="row">
            {{ getPaymentMethodName(row.value) }}
          </template>

          <!-- Column: booking -->
          <template #cell(booking)="row">
            <template v-if="row.value || row.value === 0">
              <span v-if="row.value > 0">
                +{{ formatCurrency(row.value) }}
              </span>
              <span v-if="row.value < 0">
                {{ formatCurrency(row.value) }}
              </span>
              <span v-if="row.value === 0"> 0 € </span>
            </template>
            <span v-else> - </span>
          </template>

          <!-- Column: securityDeposit -->
          <template #cell(securityDeposit)="row">
            <template v-if="row.value || row.value === 0">
              <span v-if="row.value > 0">
                +{{ formatCurrency(row.value) }}
              </span>
              <span v-if="row.value < 0">
                {{ formatCurrency(row.value) }}
              </span>
              <span v-if="row.value === 0"> 0 € </span>
            </template>
            <span v-else> - </span>
          </template>

          <!-- Column: touristTax -->
          <template #cell(touristTax)="row">
            <template v-if="row.value || row.value === 0">
              <div
                :id="`tourist-tax-description-${row.item.customId}-container`"
              >
                <div :id="`tourist-tax-description-${row.item.customId}`">
                  <span v-if="row.value > 0">
                    +{{ formatCurrency(row.value) }}
                  </span>
                  <span v-if="row.value < 0">
                    {{ formatCurrency(row.value) }}
                  </span>
                  <span v-if="row.value === 0"> 0 € </span>
                </div>
              </div>
            </template>
            <span v-else> - </span>
            <b-popover
              v-if="itemHasTouristTaxDescription(row.item)"
              title="Descripció Impost Turístic"
              :target="`tourist-tax-description-${row.item.customId}`"
              triggers="hover"
              placement="left"
              :container="`tourist-tax-description-${row.item.customId}-container`"
            >
              <ul>
                <li
                  v-for="(bookingService, index) in row.item
                    .touristTaxDescriptions"
                  :key="`popover-tourist-tax-${index}`"
                >
                  {{ getBookingServiceName(bookingService) }}
                  <ul>
                    <li>
                      PVP:
                      {{
                        getBookingServicePvpPrice(
                          bookingService,
                          row.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                    <li>
                      Propietari:
                      {{
                        getBookingServiceCostPrice(
                          bookingService,
                          row.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </b-popover>
          </template>

          <!-- Column: services -->
          <template #cell(services)="row">
            <template v-if="row.value || row.value === 0">
              <div :id="`services-description-${row.item.customId}-container`">
                <div :id="`services-description-${row.item.customId}`">
                  <span v-if="row.value > 0">
                    +{{ formatCurrency(row.value) }}
                  </span>
                  <span v-if="row.value < 0">
                    {{ formatCurrency(row.value) }}
                  </span>
                  <span v-if="row.value === 0"> 0 € </span>
                </div>
              </div>
            </template>
            <span v-else> - </span>
            <b-popover
              v-if="itemHasServicesDescriptions(row.item)"
              title="Descripció serveis"
              :target="`services-description-${row.item.customId}`"
              triggers="hover"
              placement="left"
              :container="`services-description-${row.item.customId}-container`"
            >
              <ul>
                <li
                  v-for="(bookingService, index) in row.item
                    .servicesDescriptions"
                  :key="`popover-services-${index}`"
                >
                  {{ getBookingServiceName(bookingService) }}
                  <ul>
                    <li>
                      PVP:
                      {{
                        getBookingServicePvpPrice(
                          bookingService,
                          row.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                    <li>
                      Propietari:
                      {{
                        getBookingServiceCostPrice(
                          bookingService,
                          row.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </b-popover>
          </template>

          <!-- Column: total -->
          <template #cell(total)="row">
            <template v-if="row.value || row.value === 0">
              <span v-if="row.value > 0" class="text-success">
                +{{ formatCurrency(row.value) }}
              </span>
              <span v-if="row.value < 0" class="text-danger">
                {{ formatCurrency(row.value) }}
              </span>
              <span v-if="row.value === 0"> 0 € </span>
            </template>
            <span v-else> - </span>
          </template>

          <!-- Column: actions -->
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-button
                v-b-tooltip.hover="
                  `${
                    row.detailsShowing ? 'Amaga' : 'Mostra'
                  } detalls dels pagaments`
                "
                size="sm"
                class="btn-icon mr-50"
                variant="primary"
                @click="row.toggleDetails"
              >
                <feather-icon icon="ListIcon" />
              </b-button>

              <b-button
                v-b-tooltip.hover="'Verificar pagament'"
                size="sm"
                class="btn-icon"
                variant="primary"
                @click="confirmVerifyPayments(row.item)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </div>
          </template>

          <!-- Row details -->
          <template #row-details="row">
            <b-card>
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>Concepte</b-th>
                    <b-th>Import</b-th>
                    <b-th>Comentari</b-th>
                    <b-th>Estat</b-th>
                    <b-th>Accions</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="clientPayment in row.item.clientPayments"
                    :key="clientPayment.uuid"
                  >
                    <b-td>
                      {{
                        getClientPaymentScopeName(
                          clientPayment.scope,
                          $i18n.locale
                        )
                      }}
                    </b-td>
                    <b-td>{{ formatCurrency(clientPayment.pvpAmount) }}</b-td>
                    <b-td>
                      {{ clientPayment.comment || "Sense comentaris" }}
                    </b-td>
                    <b-td>
                      <b-badge
                        pill
                        :variant="
                          getClientPaymentStatusColor(clientPayment.status)
                        "
                      >
                        {{ getClientPaymentStatusName(clientPayment.status) }}
                      </b-badge>
                    </b-td>
                    <b-td>
                      <b-button
                        v-b-tooltip.hover="'Verificar pagament'"
                        size="sm"
                        class="btn-icon"
                        variant="primary"
                        @click="confirmVerifyPayment(clientPayment.uuid)"
                      >
                        <feather-icon icon="CheckIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
          </template>

          <!-- Footer -->
          <template #foot(sortDate)>
            <span />
          </template>
          <template #foot(bookingSortCheckin)>
            <span />
          </template>
          <template #foot(accommodation)>
            <span />
          </template>
          <template #foot(method)>
            <span />
          </template>
          <template #foot(localizator)>
            <span />
          </template>
          <template #foot(booking)>
            <span>
              {{ formatCurrency(paymentsBookingSum) }}
            </span>
          </template>
          <template #foot(securityDeposit)>
            <span>
              {{ formatCurrency(paymentsSecurityDepositSum) }}
            </span>
          </template>
          <template #foot(touristTax)>
            <span>{{ formatCurrency(paymentsTouristTaxSum) }}</span>
          </template>
          <template #foot(services)>
            <span>{{ formatCurrency(paymentsServicesSum) }}</span>
          </template>
          <template #foot(total)>
            <span>{{ formatCurrency(paymentsTotalSum) }}</span>
          </template>
          <template #foot(actions)>
            <span />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  VBTooltip,
  VBPopover,
  BPopover,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import {
  formatCurrency,
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getBookingServiceClientTotalPriceText,
  getBookingServiceOwnerTotalPriceText,
  getClientPaymentScopeName,
  getClientPaymentStatusName,
  getPaymentMethodName,
  getServiceName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BBookingLocalizator,
    BAccommodation,
    BPopover,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    payments: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: true,
      sortBy: "bookingSortCheckin",
      sortDesc: false,
      fields: [
        {
          key: "sortDate",
          label: this.$t("Data"),
          sortable: true,
        },
        {
          key: "bookingSortCheckin",
          label: this.$t("Reserva"),
          sortable: true,
        },
        {
          key: "accommodation",
          label: this.$t("Allotjament"),
          sortable: true,
        },
        {
          key: "method",
          label: this.$t("Mètode"),
          sortable: true,
        },
        {
          key: "localizator",
          label: this.$t("Localitzador"),
          sortable: false,
        },
        {
          key: "booking",
          label: this.$t("Reserva"),
          sortable: true,
        },
        {
          key: "securityDeposit",
          label: this.$t("Fiança"),
          sortable: true,
        },
        {
          key: "touristTax",
          label: this.$t("Impost turístic"),
          sortable: true,
        },
        {
          key: "services",
          label: this.$t("Serveis"),
          sortable: true,
        },
        {
          key: "total",
          label: this.$t("Total"),
          sortable: true,
        },
        { key: "actions", label: this.$t("Accions"), sortable: false },
      ],
      formatDateStringToDate,
      paymentsBookingSum: 0,
      paymentsSecurityDepositSum: 0,
      paymentsTouristTaxSum: 0,
      paymentsServicesSum: 0,
      paymentsTotalSum: 0,
      confirmVerifyPaymentAlertConfig: {
        title: "Verificar pagament",
        text: "Estàs a punt de verificar aquest pagament, estàs segur/a?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Verifica",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      },
    };
  },
  watch: {
    loading(loading) {
      this.isBusy = loading;
    },
    payments(payments) {
      this.updatePatmentsSums(payments);
    },
  },
  created() {
    this.updatePatmentsSums(this.payments);
  },
  methods: {
    confirmVerifyPayments(item) {
      this.$swal(this.confirmVerifyPaymentAlertConfig).then((result) => {
        if (result.value) {
          const uuids = item.clientPayments.map(
            (clientPayment) => clientPayment.uuid
          );

          this.verifyPayments(uuids);
        }
      });
    },
    verifyPayments(uuids) {
      const promises = [];

      uuids.forEach((uuid) => {
        promises.push(this.verifyPayment(uuid));
      });

      return Promise.all(promises)
        .then(() => {
          notifySuccess(
            "Pagament verificat",
            "El pagament ha estat verificat correctament."
          );
        })
        .catch(() => {
          notifyError(
            "Pagament no verificat",
            "Hi ha hagut un error al verificat el pagament."
          );
        });
    },
    confirmVerifyPayment(uuid) {
      this.$swal(this.confirmVerifyPaymentAlertConfig).then((result) => {
        if (result.value) {
          // Update the payment
          this.verifyPayment(uuid)
            .then(() => {
              notifySuccess(
                "Pagament verificat",
                "El pagament ha estat verificat correctament."
              );
            })
            .catch(() => {
              notifyError(
                "Pagament no verificat",
                "Hi ha hagut un error al verificat el pagament."
              );
            });
        }
      });
    },
    verifyPayment(uuid) {
      return this.$store.dispatch("clientPayments/updatePayment", {
        uuid,
        status: "VERIFIED",
        verifiedAt: formatDateObjectToDatabaseDateTime(new Date()),
      });
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    getClientPaymentScopeName(code) {
      return getClientPaymentScopeName(code, this.$i18n.locale);
    },
    getClientPaymentStatusName(code) {
      return getClientPaymentStatusName(code, this.$i18n.locale);
    },
    getClientPaymentStatusColor(code) {
      switch (code) {
        case "CONFIRMED":
          return "success";
        default:
          return "secondary";
      }
    },
    getPaymentMethodName(code) {
      return getPaymentMethodName(code, this.$i18n.locale);
    },
    getBookingServiceName(bookingService) {
      if (!bookingService?.service) return null;
      return getServiceName(bookingService.service, this.$i18n.locale);
    },
    getBookingServicePvpPrice(bookingService, nights) {
      if (!bookingService?.pvpPrice) return null;
      return getBookingServiceClientTotalPriceText(
        bookingService,
        nights,
        this.$i18n.locale
      );
    },
    getBookingServiceCostPrice(bookingService, nights) {
      if (!bookingService?.costPrice) return null;
      return getBookingServiceOwnerTotalPriceText(
        bookingService,
        nights,
        this.$i18n.locale
      );
    },
    itemHasServicesDescriptions(item) {
      return item?.servicesDescriptions?.length || false;
    },
    itemHasTouristTaxDescription(item) {
      return item?.touristTaxDescriptions?.length || false;
    },
    isCurrentYear(dateString) {
      if (!dateString) return null;
      const currentYear = new Date().getFullYear();
      return dateString.includes(currentYear);
    },
    formatClientPaymentDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    updatePatmentsSums(payments) {
      if (!payments.length) {
        this.paymentsBookingSum = 0;
        this.paymentsSecurityDepositSum = 0;
        this.paymentsTouristTaxSum = 0;
        this.paymentsServicesSum = 0;
        this.paymentsTotalSum = 0;
        return;
      }

      this.paymentsBookingSum = 0;
      this.paymentsSecurityDepositSum = 0;
      this.paymentsTouristTaxSum = 0;
      this.paymentsServicesSum = 0;
      this.paymentsTotalSum = 0;

      payments.forEach((payment) => {
        this.paymentsBookingSum += payment.booking || 0;
        this.paymentsSecurityDepositSum += payment.securityDeposit || 0;
        this.paymentsTouristTaxSum += payment.touristTax || 0;
        this.paymentsServicesSum += payment.services || 0;
        this.paymentsTotalSum += payment.total || 0;
      });
    },
    onTableFiltered(filteredRows) {
      this.updatePatmentsSums(filteredRows);
    },
  },
};
</script>
