<template>
  <b-card id="verify-payments-card" title="Pagaments pendents de verificar">
    <!-- FILTERS BAR -->
    <b-row class="d-flex justify-content-between align-items-center mb-1">
      <b-col cols="2">
        <v-select
          v-model="otaFilter"
          :options="otaFilterOptions"
          :reduce="(option) => option.value"
          placeholder="Canal"
          :disabled="loadingPayments"
          class="w-100 ota-filter mr-1"
        />
      </b-col>
      <b-col cols="2">
        <b-form-input
          v-model="searchFilter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loadingPayments"
          debounce="400"
          autofocus
          class="mr-1"
        />
      </b-col>
    </b-row>

    <!-- PAYMENTS TABLE -->
    <verify-payments-table
      :payments="filteredPayments"
      :loading="loadingPayments"
      :filter="searchFilter"
    />
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput } from "bootstrap-vue";
import VerifyPaymentsTable from "@/views/accounting/payments/verify-payments/components/VerifyPaymentsTable.vue";
import { notifyError, sameDates, sameSign } from "@/utils/methods";
import {
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import { otaOptions } from "@/utils/select-options";
import vSelect from "vue-select";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BFormInput,
    VerifyPaymentsTable,
  },
  data() {
    return {
      otaFilter: null,
      searchFilter: null,
    };
  },
  computed: {
    loadingPayments() {
      return this.$store.getters["clientPayments/loading"];
    },
    payments() {
      const unverifiedClientPayments =
        this.$store.getters["clientPayments/payments"];

      const payments = [];

      unverifiedClientPayments.forEach((unverifiedClientPayment) => {
        const { localizator, booking, method, status, date, pvpAmount } =
          unverifiedClientPayment;

        const paymentIndex = payments.findIndex((payment) => {
          const baseCheck =
            !!booking?.localizator &&
            booking.localizator === payment.bookingLocalizator &&
            sameDates(
              new Date(formatDateStringToDatabaseDate(date)),
              new Date(payment.sortDate)
            ) &&
            method === payment.method &&
            sameSign(pvpAmount, payment.total);

          switch (unverifiedClientPayment.method) {
            case "SOURCE_PAYMENT":
              return baseCheck;
            default:
              return (
                baseCheck &&
                !!localizator &&
                localizator === payment.localizator
              );
          }
        });

        if (paymentIndex === -1) {
          // payment has not been created yet, create it
          const payment = {
            customId: uuidv4(),
            localizator,
            method,
            status,
            date,
            sortDate: formatDateStringToDatabaseDate(date) || null,
            displayDate: this.formatDate(date) || null,
            bookingUuid: booking?.uuid || null,
            bookingLocalizator: booking?.localizator || null,
            bookingDisplayCheckin:
              formatDateStringToDate(booking?.checkin) || null,
            bookingSortCheckin:
              formatDateStringToDatabaseDate(booking?.checkin) || null,
            bookingDisplayCheckout:
              formatDateStringToDate(booking?.checkout) || null,
            bookingNights: booking?.nights || null,
            bookingSource: booking?.source || null,
            bookingCancelled: booking?.cancelled || null,
            bookingHighlighted: booking?.highlighted || false,
            accommodation: booking?.accommodation || null,
            total: 0,
            booking: 0,
            securityDeposit: 0,
            touristTax: 0,
            touristTaxDescriptions: [],
            services: 0,
            servicesDescriptions: [],
            clientPayments: [],
          };

          payment.clientPayments.push(unverifiedClientPayment);

          this.updatePaymentAmounts(payment, unverifiedClientPayment);

          payments.push(payment);
        } else {
          // payment found, update it
          const payment = payments[paymentIndex];

          payment.clientPayments.push(unverifiedClientPayment);

          this.updatePaymentAmounts(payment, unverifiedClientPayment);

          payments.splice(paymentIndex, 1, payment);
        }
      });

      return payments;
    },
    filteredPayments() {
      if (!this.payments?.length) {
        return [];
      }

      return this.payments
        .filter((payment) => {
          return payment.status !== "VERIFIED";
        })
        .filter((payment) => {
          // Filter by OTA
          if (!this.otaFilter) {
            return true;
          }

          if (this.otaFilter === "TPV") {
            return payment.method === "CREDIT_CARD";
          }

          if (this.otaFilter === "BANK_TRANSFERS") {
            return payment.method === "BANK_TRANSFER";
          }

          return (
            payment.bookingSource === this.otaFilter &&
            payment.method !== "BANK_TRANSFER" &&
            payment.method !== "CREDIT_CARD"
          );
        });
    },
    otaFilterOptions() {
      const otasWithSelfManagedPayments = otaOptions.filter(
        (ota) => ota.selfManagedPayments
      );
      return [
        {
          label: "TPV's",
          value: "TPV",
        },
        {
          label: "Transferències",
          value: "BANK_TRANSFERS",
        },
        ...otasWithSelfManagedPayments,
      ];
    },
  },
  created() {
    this.fetchPayments();
  },
  beforeDestroy() {
    this.$store.dispatch("clientPayments/reset");
  },
  methods: {
    fetchPayments() {
      this.$store
        .dispatch("clientPayments/fetchUnverifiedPayments")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchClientPayments.title"),
            this.$t("errors.fetchClientPayments.description")
          );
        });
    },
    formatDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    updatePaymentAmounts(payment, unverifiedPayment) {
      const { scope, pvpAmount, bookingService } = unverifiedPayment || {};

      payment.total += pvpAmount;

      switch (scope) {
        case "BOOKING":
          payment.booking += pvpAmount;
          break;
        case "SECURITY_DEPOSIT":
          payment.securityDeposit += pvpAmount;
          break;
        case "TOURIST_TAX":
          payment.touristTax += pvpAmount;
          payment.touristTaxDescriptions.push(bookingService);
          break;
        case "SERVICE":
        default:
          payment.services += pvpAmount;
          payment.servicesDescriptions.push(bookingService);
          break;
      }

      return payment;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#verify-payments-card {
  .ota-filter {
    min-width: 200px;
  }
}
</style>
